import React from "react"
import { Typography} from "antd"
import "./RefundPolicy.less"

const { Title, Text } = Typography

export default function RefundPolicy() {
  return (
    <div className="refundPolicyParent">
      <div className="refundPolicyDiv">
        <Title className="title mainTitle">Payment and Refund Policy</Title>
        <Text className="text">
          This page informs visitors regarding our policies with collection of payments and refunds.
        </Text>
        <Title className="title">Payment Policy</Title>
        <ul>
          <li>
            Mentro accepts payment by credit card, debit card, PayPal, and bank transfer. All payment options are subject to verification and approval by Mentro.
          </li>
          <li>
            Payment for courses and mentorship sessions must be made in full prior to the start of the course or session. Payment plans must be agreed upon by both Mentro and the customer prior to the start of the course or session.
          </li>
          <li>
            Mentro takes customer security seriously and implements industry-standard security measures to ensure that customer information is protected. Mentro does not store any credit card or debit card information on its servers.
          </li>
        </ul>
        <Title className="title">Refund Policy</Title>
        <ul>
          <li>
            Customers may request a full refund for any course within 14 days of purchase. After the 14-day period, no refunds will be issued. Refunds will only be issued if the customer has not completed more than 20% of the course content.
          </li>
          <li>
            Customers may request a refund for any mentorship session within 24 hours of the session. After the 24-hour period, no refunds will be issued.
          </li>
          <li>
            To request a refund, customers must contact our customer support team via email at 
            <a href="mailto:contact@mentro.tech" target={"_blank"}>
              contact@mentro.tech{" "}
            </a>. Refunds will be issued to the original payment method within 7 business days of approval.
          </li>
          <li>
            Mentro reserves the right to cancel any course or mentorship session at any time. In the event of a cancellation, Mentro will issue a full refund to the customer. Mentro is not responsible for any additional costs incurred by the customer as a result of the cancellation.
          </li>
        </ul>
      </div>
    </div>
  )
}
