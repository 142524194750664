import React from "react"
import "../../theme/info.less"
import Container from "../../info/components/Container"
import Seo from "../../components/seo"
import RefundPolicy from "../../info/components/RefundPolicy"

const RefundPolicyPage = () => {
  return (
    <Container>
      <Seo title="Mentro: Learn with Mentors" />
      <RefundPolicy />
    </Container>
  )
}

export default RefundPolicyPage
